<template>
  <!-- Topbar Start -->
  <div class="container-fluid bg-primary px-5 d-none d-lg-block">
    <div class="row gx-0 align-items-center">
      <div class="col-lg-5 text-center text-lg-start mb-lg-0">
        <div class="d-flex">
          <a href="#" class="text-muted me-4"
            ><i class="fas fa-envelope text-secondary me-2"></i
            >info@elrukzgroup.co.rw</a
          >
          <a href="#" class="text-muted me-0"
            ><i class="fas fa-phone-alt text-secondary me-2"></i
            >+250780401441</a
          >
        </div>
      </div>
      <div class="col-lg-3 row-cols-1 text-center mb-2 mb-lg-0">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <a
            class="btn btn-sm btn-outline-light btn-square rounded-circle me-2"
            href=""
            ><i class="fab fa-twitter fw-normal text-secondary"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-square rounded-circle me-2"
            href=""
            ><i class="fab fa-facebook-f fw-normal text-secondary"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-square rounded-circle me-2"
            href=""
            ><i class="fab fa-linkedin-in fw-normal text-secondary"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-square rounded-circle me-2"
            href=""
            ><i class="fab fa-instagram fw-normal text-secondary"></i
          ></a>
          <a
            class="btn btn-sm btn-outline-light btn-square rounded-circle"
            href=""
            ><i class="fab fa-youtube fw-normal text-secondary"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-4 text-center text-lg-end">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <a href="#" class="text-muted me-2"> Help</a><small> / </small>
          <a href="#" class="text-muted mx-2"> Support</a><small> / </small>
          <a href="/contact" class="text-muted ms-2"> Contact</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar & Hero Start -->
  <div class="container-fluid nav-bar p-0">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0"
    >
      <a href="" class="navbar-brand p-0">
        <h1 class="display-5 text-secondary m-0">
          <img src="img/elrkz Logo-01.jpeg" class="img-fluid" alt="" />Elrukz
          Group
        </h1>
        <!-- <img src="img/logo.png" alt="Logo"> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="fa fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto py-0">
          <a href="/" class="nav-item nav-link active">Home</a>
          <a href="/about" class="nav-item nav-link">About</a>
          <a href="/service" class="nav-item nav-link">Service</a>
          <!-- <div class="nav-item dropdown">
            <a href="#" class="nav-link" data-bs-toggle="dropdown"
              ><span class="dropdown-toggle">Pages</span></a
            >
            <div class="dropdown-menu m-0">
              <a href="feature.html" class="dropdown-item">Feature</a>
              <a href="countries.html" class="dropdown-item">Countries</a>
              <a href="testimonial.html" class="dropdown-item">Testimonial</a>
              <a href="training.html" class="dropdown-item">Training</a>
              <a href="404.html" class="dropdown-item">404 Page</a>
            </div>
          </div> -->
          <a href="/contact" class="nav-item nav-link">Contact</a>
        </div>
        <button
          class="btn btn-primary btn-md-square border-secondary mb-3 mb-md-3 mb-lg-0 me-3"
          data-bs-toggle="modal"
          data-bs-target="#searchModal"
        >
          <i class="fas fa-search"></i>
        </button>
        <a
          href=""
          class="btn btn-primary border-secondary rounded-pill py-2 px-4 px-lg-3 mb-3 mb-md-3 mb-lg-0"
          >Get A Quote</a
        >
      </div>
    </nav>
  </div>
  <!-- Navbar & Hero End -->

  <!-- Carousel Start -->
  <div class="carousel-header">
    <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li
          data-bs-target="#carouselId"
          data-bs-slide-to="0"
          class="active"
        ></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <img src="img/carousel-1.jpg" class="img-fluid" alt="Image" />
          <div class="carousel-caption">
            <div class="text-center p-4" style="max-width: 900px">
              <h4
                class="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                Solution For All Type Of Visas
              </h4>
              <h1
                class="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                Immigration Process Starts Here!
              </h1>
              <p
                class="text-white mb-4 mb-md-5 fs-5 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                Tired of visa confusion? We simplify the process!
              </p>
              <!-- <a
                class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp"
                data-wow-delay="0.7s"
                href="#"
                >More Details</a
              > -->
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img src="img/carousel-2.jpg" class="img-fluid" alt="Image" />
          <div class="carousel-caption">
            <div class="text-center p-4" style="max-width: 900px">
              <h5
                class="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                Solution For All Type Of Visas
              </h5>
              <h1
                class="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                Best Visa Immigrations Services
              </h1>
              <p
                class="text-white mb-4 mb-md-5 fs-5 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                We offer expert guidance and support for every visa type. Get
                started today!
              </p>
              <!-- <a
                class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp"
                data-wow-delay="0.7s"
                href="#"
                >More Details</a
              > -->
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselId"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon bg-secondary wow fadeInLeft"
          data-wow-delay="0.2s"
          aria-hidden="false"
        ></span>
        <span class="visually-hidden-focusable">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselId"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon bg-secondary wow fadeInRight"
          data-wow-delay="0.2s"
          aria-hidden="false"
        ></span>
        <span class="visually-hidden-focusable">Next</span>
      </button>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h4 class="modal-title text-secondary mb-0" id="exampleModalLabel">
            Search by keyword
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->

  <!-- About Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
          <div class="bg-light rounded">
            <img
              src="img/about-2.png"
              class="img-fluid w-100"
              style="margin-bottom: -7px"
              alt="Image"
            />
            <img
              src="img/about-3.jpg"
              class="img-fluid w-100 border-bottom border-5 border-primary"
              style="
                border-top-right-radius: 300px;
                border-top-left-radius: 300px;
              "
              alt="Image"
            />
          </div>
        </div>
        <div class="col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
          <h5 class="sub-title pe-3">About the company</h5>
          <h1 class="display-5 mb-4">
            We’re Trusted Immigration Consultant Agency.
          </h1>
          <p class="mb-4">
            Elrukz Group Educational Consultancy, It's located at Kigali, Rwanda
            in the kimironko road, In the building house called Martin Plaza F2
            05 KG 182 street.
          </p>
          <div class="row gy-4 align-items-center">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <i class="fas fa-map-marked-alt fa-3x text-secondary"></i>
              <h5 class="ms-4">Best Immigration Resources</h5>
            </div>
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <i class="fas fa-passport fa-3x text-secondary"></i>
              <h5 class="ms-4">Return Visas Availabile</h5>
            </div>
            <div class="col-4 col-md-3">
              <div class="bg-light text-center rounded p-3">
                <div class="mb-2">
                  <i class="fas fa-ticket-alt fa-4x text-primary"></i>
                </div>
                <h1 class="display-5 fw-bold mb-2">8</h1>
                <p class="text-muted mb-0">Years of Experience</p>
              </div>
            </div>
            <div class="col-8 col-md-9">
              <div class="mb-5">
                <p class="text-primary h6 mb-3">
                  <i class="fa fa-check-circle text-secondary me-2"></i> Offer
                  100 % Genuine Assistance
                </p>
                <p class="text-primary h6 mb-3">
                  <i class="fa fa-check-circle text-secondary me-2"></i> It’s
                  Faster & Reliable Execution
                </p>
                <p class="text-primary h6 mb-3">
                  <i class="fa fa-check-circle text-secondary me-2"></i>
                  Accurate & Expert Advice
                </p>
              </div>
              <div class="d-flex flex-wrap">
                <div
                  id="phone-tada"
                  class="d-flex align-items-center justify-content-center me-4"
                >
                  <a
                    href=""
                    class="position-relative wow tada"
                    data-wow-delay=".9s"
                  >
                    <i class="fa fa-phone-alt text-primary fa-3x"></i>
                    <div class="position-absolute" style="top: 0; left: 25px">
                      <span
                        ><i class="fa fa-comment-dots text-secondary"></i
                      ></span>
                    </div>
                  </a>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <span class="text-primary">Have any questions?</span>
                  <span
                    class="text-secondary fw-bold fs-5"
                    style="letter-spacing: 2px"
                    >Free: +250780401441</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Counter Facts Start -->
  <div class="container-fluid counter-facts py-5">
    <div class="container py-5">
      <div class="row g-4">
        <div
          class="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="counter">
            <div class="counter-icon">
              <i class="fas fa-passport"></i>
            </div>
            <div class="counter-content">
              <h3>Visa Categories</h3>
              <div class="d-flex align-items-center justify-content-center">
                <span class="counter-value" data-toggle="counter-up">31</span>
                <h4
                  class="text-secondary mb-0"
                  style="font-weight: 600; font-size: 25px"
                >
                  +
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="counter">
            <div class="counter-icon">
              <i class="fas fa-users"></i>
            </div>
            <div class="counter-content">
              <h3>Team Members</h3>
              <div class="d-flex align-items-center justify-content-center">
                <span class="counter-value" data-toggle="counter-up">377</span>
                <h4
                  class="text-secondary mb-0"
                  style="font-weight: 600; font-size: 25px"
                >
                  +
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div class="counter">
            <div class="counter-icon">
              <i class="fas fa-user-check"></i>
            </div>
            <div class="counter-content">
              <h3>Visa Process</h3>
              <div class="d-flex align-items-center justify-content-center">
                <span class="counter-value" data-toggle="counter-up">4.9</span>
                <h4
                  class="text-secondary mb-0"
                  style="font-weight: 600; font-size: 25px"
                >
                  K
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.7s"
        >
          <div class="counter">
            <div class="counter-icon">
              <i class="fas fa-handshake"></i>
            </div>
            <div class="counter-content">
              <h3>Success Rates</h3>
              <div class="d-flex align-items-center justify-content-center">
                <span class="counter-value" data-toggle="counter-up">98</span>
                <h4
                  class="text-secondary mb-0"
                  style="font-weight: 600; font-size: 25px"
                >
                  %
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Counter Facts End -->

  <!-- Services Start -->
  <div class="container-fluid service overflow-hidden pt-5">
    <div class="container py-5">
      <div
        class="section-title text-center mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="sub-style">
          <h5 class="sub-title text-primary px-3">Visa Categories</h5>
        </div>
        <h1 class="display-5 mb-4">Enabling Your Immigration Successfully</h1>
        <p class="mb-0">
          Unclear visa requirements holding back your international dreams? We
          offer a comprehensive range of solutions for all visa categories,
          empowering you to navigate the immigration process seamlessly and
          achieve your relocation goals.
        </p>
      </div>
      <div class="row g-4">
        <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item">
            <div class="service-inner">
              <div class="service-img">
                <img
                  src="img/service-1.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
              </div>
              <div class="service-title">
                <div class="service-title-name">
                  <div class="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" class="h4 text-white mb-0">Job Visa</a>
                  </div>
                  <a
                    class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4"
                    href="#"
                    >Explore More</a
                  >
                </div>
                <div class="service-content pb-4">
                  <a href="#">
                    <h4 class="text-white mb-4 py-3">Job Visa</h4>
                  </a>
                  <div class="px-4">
                    <p class="mb-4">
                      Seeking a new career opportunity abroad? Our job visa
                      solutions can help! We offer expert guidance and support
                      specifically tailored to employment-based visas.
                    </p>
                    <a
                      class="btn btn-primary border-secondary rounded-pill py-3 px-5"
                      href="#"
                      >Explore More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item">
            <div class="service-inner">
              <div class="service-img">
                <img
                  src="img/service-2.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
              </div>
              <div class="service-title">
                <div class="service-title-name">
                  <div class="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" class="h4 text-white mb-0">Business Visa</a>
                  </div>
                  <a
                    class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4"
                    href="#"
                    >Explore More</a
                  >
                </div>
                <div class="service-content pb-4">
                  <a href="#">
                    <h4 class="text-white mb-4 py-3">Business Visa</h4>
                  </a>
                  <div class="px-4">
                    <p class="mb-4">
                      Unleash global business potential with our streamlined
                      business visa solutions.
                    </p>
                    <a
                      class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5"
                      href="#"
                      >Explore More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item">
            <div class="service-inner">
              <div class="service-img">
                <img
                  src="img/service-3.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
              </div>
              <div class="service-title">
                <div class="service-title-name">
                  <div class="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" class="h4 text-white mb-0">Diplometic Visa</a>
                  </div>
                  <a
                    class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4"
                    href="#"
                    >Explore More</a
                  >
                </div>
                <div class="service-content pb-4">
                  <a href="#">
                    <h4 class="text-white mb-4 py-3">Diplometic Visa</h4>
                  </a>
                  <div class="px-4">
                    <p class="mb-4">
                      We handle the intricacies of accreditation and application
                      processes, expediting your entry and fostering
                      international cooperation.
                    </p>
                    <a
                      class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5"
                      href="#"
                      >Explore More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item">
            <div class="service-inner">
              <div class="service-img">
                <img
                  src="img/service-1.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
              </div>
              <div class="service-title">
                <div class="service-title-name">
                  <div class="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" class="h4 text-white mb-0">Students Visa</a>
                  </div>
                  <a
                    class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4"
                    href="#"
                    >Explore More</a
                  >
                </div>
                <div class="service-content pb-4">
                  <a href="#">
                    <h4 class="text-white mb-4 py-3">Students Visa</h4>
                  </a>
                  <div class="px-4">
                    <p class="mb-4">
                      We simplify the application process, ensuring you focus on
                      pursuing your educational goals and exploring new horizons
                      with a stress-free visa journey.
                    </p>
                    <a
                      class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5"
                      href="#"
                      >Explore More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item">
            <div class="service-inner">
              <div class="service-img">
                <img
                  src="img/service-2.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
              </div>
              <div class="service-title">
                <div class="service-title-name">
                  <div class="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" class="h4 text-white mb-0">Residence Visa</a>
                  </div>
                  <a
                    class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4"
                    href="#"
                    >Explore More</a
                  >
                </div>
                <div class="service-content pb-4">
                  <a href="#">
                    <h4 class="text-white mb-4 py-3">Residence Visa</h4>
                  </a>
                  <div class="px-4">
                    <p class="mb-4">
                      Turn your dreams of living and working abroad into reality
                      with our comprehensive residence visa solutions.
                    </p>
                    <a
                      class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5"
                      href="#"
                      >Explore More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item">
            <div class="service-inner">
              <div class="service-img">
                <img
                  src="img/service-3.jpg"
                  class="img-fluid w-100 rounded"
                  alt="Image"
                />
              </div>
              <div class="service-title">
                <div class="service-title-name">
                  <div class="bg-primary text-center rounded p-3 mx-5 mb-4">
                    <a href="#" class="h4 text-white mb-0">Tourist Visa</a>
                  </div>
                  <a
                    class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4"
                    href="#"
                    >Explore More</a
                  >
                </div>
                <div class="service-content pb-4">
                  <a href="#">
                    <h4 class="text-white mb-4 py-3">Tourist Visa</h4>
                  </a>
                  <div class="px-4">
                    <p class="mb-4">
                      Experience the wonder of international travel with our
                      hassle-free tourist visa services.
                    </p>
                    <a
                      class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5"
                      href="#"
                      >Explore More</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Services End -->

  <!-- Features Start -->
  <div class="container-fluid features overflow-hidden py-5">
    <div class="container">
      <div
        class="section-title text-center mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="sub-style">
          <h5 class="sub-title text-primary px-3">Why Choose Us</h5>
        </div>
        <h1 class="display-5 mb-4">
          Offer Tailor Made Services That Our Client Requires
        </h1>
        <p class="mb-0">
          We don't just process visas, we empower your journeys. Our
          comprehensive solutions for all visa categories, expert guidance, and
          commitment to streamlining the process ensure a smooth and successful
          immigration experience. pen_spark
        </p>
      </div>
      <div class="row g-4 justify-content-center text-center">
        <div
          class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="feature-item text-center p-4">
            <div class="feature-icon p-3 mb-4">
              <i class="fas fa-dollar-sign fa-4x text-primary"></i>
            </div>
            <div class="feature-content d-flex flex-column">
              <h5 class="mb-3">Cost-Effective</h5>
              <p class="mb-3">
                Our cost-effective solutions ensure you get the expertise and
                support you need for any visa category, all at a competitive
                price
              </p>
              <!-- <a class="btn btn-secondary rounded-pill" href="#"
                >Read More<i class="fas fa-arrow-right ms-2"></i
              ></a> -->
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="feature-item text-center p-4">
            <div class="feature-icon p-3 mb-4">
              <i class="fab fa-cc-visa fa-4x text-primary"></i>
            </div>
            <div class="feature-content d-flex flex-column">
              <h5 class="mb-3">Visa Assistance</h5>
              <p class="mb-3">
                Our visa assistance simplifies the process from start to finish. We offer cost-effective solutions for all visa categories
              </p>
              <!-- <a class="btn btn-secondary rounded-pill" href="#"
                >Read More<i class="fas fa-arrow-right ms-2"></i
              ></a> -->
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div class="feature-item text-center p-4">
            <div class="feature-icon p-3 mb-4">
              <i class="fas fa-atlas fa-4x text-primary"></i>
            </div>
            <div class="feature-content d-flex flex-column">
              <h5 class="mb-3">Faster Processing</h5>
              <p class="mb-3">
                Dolor, sit amet consectetur adipisicing elit. Soluta inventore
                cum accusamus,
              </p>
              <a class="btn btn-secondary rounded-pill" href="#"
                >Read More<i class="fas fa-arrow-right ms-2"></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.7s"
        >
          <div class="feature-item text-center p-4">
            <div class="feature-icon p-3 mb-4">
              <i class="fas fa-users fa-4x text-primary"></i>
            </div>
            <div class="feature-content d-flex flex-column">
              <h5 class="mb-3">Direct Interviews</h5>
              <p class="mb-3">
                Dolor, sit amet consectetur adipisicing elit. Soluta inventore
                cum accusamus,
              </p>
              <a class="btn btn-secondary rounded-pill" href="#"
                >Read More<i class="fas fa-arrow-right ms-2"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-12">
          <a
            class="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp"
            data-wow-delay="0.1s"
            href="#"
            >More Features</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Features End -->

  <!-- Countries We Offer Start -->
  <div class="container-fluid country overflow-hidden py-5">
    <div class="container">
      <div
        class="section-title text-center wow fadeInUp"
        data-wow-delay="0.1s"
        style="margin-bottom: 70px"
      >
        <div class="sub-style">
          <h5 class="sub-title text-primary px-3">COUNTRIES WE OFFER</h5>
        </div>
        <h1 class="display-5 mb-4">
          Immigration & visa services following Countries
        </h1>
        <p class="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
          deleniti amet at atque sequi quibusdam cumque itaque repudiandae
          temporibus, eius nam mollitia voluptas maxime veniam necessitatibus
          saepe in ab? Repellat!
        </p>
      </div>
      <div class="row g-4 text-center">
        <div
          class="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="country-item">
            <div class="rounded overflow-hidden">
              <img
                src="img/country-1.jpg"
                class="img-fluid w-100 rounded"
                alt="Image"
              />
            </div>
            <div class="country-flag">
              <img
                src="img/brazil.jpg"
                class="img-fluid rounded-circle"
                alt="Image"
              />
            </div>
            <div class="country-name">
              <a href="#" class="text-white fs-4">Brazil</a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="country-item">
            <div class="rounded overflow-hidden">
              <img
                src="img/country-2.jpg"
                class="img-fluid w-100 rounded"
                alt="Image"
              />
            </div>
            <div class="country-flag">
              <img
                src="img/india.jpg"
                class="img-fluid rounded-circle"
                alt="Image"
              />
            </div>
            <div class="country-name">
              <a href="#" class="text-white fs-4">india</a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div class="country-item">
            <div class="rounded overflow-hidden">
              <img
                src="img/country-3.jpg"
                class="img-fluid w-100 rounded"
                alt="Image"
              />
            </div>
            <div class="country-flag">
              <img
                src="img/usa.jpg"
                class="img-fluid rounded-circle"
                alt="Image"
              />
            </div>
            <div class="country-name">
              <a href="#" class="text-white fs-4">New York</a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-xl-3 mb-5 mb-xl-0 wow fadeInUp"
          data-wow-delay="0.7s"
        >
          <div class="country-item">
            <div class="rounded overflow-hidden">
              <img
                src="img/country-4.jpg"
                class="img-fluid w-100 rounded"
                alt="Image"
              />
            </div>
            <div class="country-flag">
              <img
                src="img/italy.jpg"
                class="img-fluid rounded-circle"
                alt="Image"
              />
            </div>
            <div class="country-name">
              <a href="#" class="text-white fs-4">Italy</a>
            </div>
          </div>
        </div>
        <div class="col-12">
          <a
            class="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp"
            data-wow-delay="0.1s"
            href="#"
            >More Countries</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Countries We Offer End -->

  <!-- Testimonial Start -->
  <div class="container-fluid testimonial overflow-hidden pb-5">
    <div class="container py-5">
      <div
        class="section-title text-center mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="sub-style">
          <h5 class="sub-title text-primary px-3">OUR CLIENTS RIVIEWS</h5>
        </div>
        <h1 class="display-5 mb-4">What Our Clients Say</h1>
        <p class="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
          deleniti amet at atque sequi quibusdam cumque itaque repudiandae
          temporibus, eius nam mollitia voluptas maxime veniam necessitatibus
          saepe in ab? Repellat!
        </p>
      </div>
      <div
        class="owl-carousel testimonial-carousel wow zoomInDown"
        data-wow-delay="0.2s"
      >
        <div class="testimonial-item">
          <div class="testimonial-content p-4 mb-5">
            <p class="fs-5 mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitati eiusmod tempor
              incididunt.
            </p>
            <div class="d-flex justify-content-end">
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
            </div>
          </div>
          <div class="d-flex">
            <div
              class="rounded-circle me-4"
              style="width: 100px; height: 100px"
            >
              <img
                class="img-fluid rounded-circle"
                src="img/testimonial-1.jpg"
                alt="img"
              />
            </div>
            <div class="my-auto">
              <h5>Person Name</h5>
              <p class="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div class="testimonial-item">
          <div class="testimonial-content p-4 mb-5">
            <p class="fs-5 mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitati eiusmod tempor
              incididunt.
            </p>
            <div class="d-flex justify-content-end">
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
            </div>
          </div>
          <div class="d-flex">
            <div
              class="rounded-circle me-4"
              style="width: 100px; height: 100px"
            >
              <img
                class="img-fluid rounded-circle"
                src="img/testimonial-2.jpg"
                alt="img"
              />
            </div>
            <div class="my-auto">
              <h5>Person Name</h5>
              <p class="mb-0">Profession</p>
            </div>
          </div>
        </div>
        <div class="testimonial-item">
          <div class="testimonial-content p-4 mb-5">
            <p class="fs-5 mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitati eiusmod tempor
              incididunt.
            </p>
            <div class="d-flex justify-content-end">
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
              <i class="fas fa-star text-secondary"></i>
            </div>
          </div>
          <div class="d-flex">
            <div
              class="rounded-circle me-4"
              style="width: 100px; height: 100px"
            >
              <img
                class="img-fluid rounded-circle"
                src="img/testimonial-3.jpg"
                alt="img"
              />
            </div>
            <div class="my-auto">
              <h5>Person Name</h5>
              <p class="mb-0">Profession</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonial End -->

  <!-- Training Start -->
  <div class="container-fluid training overflow-hidden bg-light py-5">
    <div class="container py-5">
      <div
        class="section-title text-center mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="sub-style">
          <h5 class="sub-title text-primary px-3">CHECK OUR CREDENTIALS</h5>
        </div>
        <h3 class="display-5 mb-4">
          Check Our Credential Certificate from different Institutions
        </h3>
        <!-- <p class="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
          deleniti amet at atque sequi quibusdam cumque itaque repudiandae
          temporibus, eius nam mollitia voluptas maxime veniam necessitatibus
          saepe in ab? Repellat!
        </p> -->
      </div>
      <div class="row g-4">
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/bau.jpeg"
                class="img-fluid w-150 rounded"
                alt="bau"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <a href="#" class="h4 text-white mb-0"
                  >Certificate of Representative</a
                >
                <!-- <a href="#" class="h4 text-white mb-0">Coaching</a> -->
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">IELTS Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/applyboard.png"
                class="img-fluid w-150 rounded"
                alt="Image"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <a href="#" class="h4 text-white mb-0"
                  >Certificate of Representative</a
                >
                <!-- <a href="#" class="h4 text-white mb-0">Coaching</a> -->
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">TOEFL Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/jain.png"
                class="img-fluid w-100 rounded"
                alt="Image"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <a href="#" class="h4 text-white mb-0"
                  >Certificate of Authorization</a
                >
                <!-- <a href="#" class="h4 text-white mb-0">Coaching</a> -->
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">PTE Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.7s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/registration.png"
                class="img-fluid w-100 rounded"
                alt="Image"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <!-- <a href="#" class="h4 text-white mb-0">OET</a> -->
                <a href="#" class="h4 text-white mb-0"
                  >RDB Domestic Registration</a
                >
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">OET Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/ssbm.png"
                class="img-fluid w-100 rounded"
                alt="bau"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <a href="#" class="h4 text-white mb-0">Agent Certificate</a>
                <!-- <a href="#" class="h4 text-white mb-0">Coaching</a> -->
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">IELTS Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/mangalt.png"
                class="img-fluid w-100 rounded"
                alt="bau"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <a href="#" class="h4 text-white mb-0"
                  >Letter of Authorization</a
                >
                <!-- <a href="#" class="h4 text-white mb-0">Coaching</a> -->
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">IELTS Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <div
          class="col-lg-6 col-lg-6 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="training-item">
            <div class="training-inner">
              <img
                src="img/Warsaw post.png"
                class="img-fluid w-100 rounded"
                alt="bau"
                width="500"
                height="500"
              />
              <div class="training-title-name">
                <a href="#" class="h4 text-white mb-0"
                  >Certificate of Representative</a
                >
                <!-- <a href="#" class="h4 text-white mb-0">Coaching</a> -->
              </div>
            </div>
            <!-- <div class="training-content bg-secondary rounded-bottom p-4">
              <a href="#">
                <h4 class="text-white">IELTS Coaching</h4>
              </a>
              <p class="text-white-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
                veritatis.
              </p>
              <a class="btn btn-secondary rounded-pill text-white p-0" href="#"
                >Read More <i class="fa fa-arrow-right"></i
              ></a>
            </div> -->
          </div>
        </div>
        <!-- <div class="col-12 text-center">
          <a
            class="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp"
            data-wow-delay="0.1s"
            href="#"
            >View More</a
          >
        </div> -->
      </div>
    </div>
  </div>
  <!-- Training End -->

  <!-- Footer Start -->
  <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="text-secondary mb-4">Contact Info</h4>
            <a href=""
              ><i class="fa fa-map-marker-alt me-2"></i> KG 182 ST, Rwahama-
              Martin Plaza F2 05,</a
            >
            <a href=""
              ><i class="fas fa-envelope me-2"></i> info@elrukzgroup.co.rw</a
            >
            <a href=""><i class="fas fa-phone me-2"></i> +250780401441</a>
            <a href="" class="mb-3"
              ><i class="fas fa-print me-2"></i> +250780401441</a
            >
            <div class="d-flex align-items-center">
              <i class="fas fa-share fa-2x text-secondary me-2"></i>
              <a class="btn mx-1" href=""><i class="fab fa-facebook-f"></i></a>
              <a class="btn mx-1" href=""><i class="fab fa-twitter"></i></a>
              <a class="btn mx-1" href=""><i class="fab fa-instagram"></i></a>
              <a class="btn mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="text-secondary mb-4">Opening Time</h4>
            <div class="mb-3">
              <h6 class="text-muted mb-0">Mon - Friday:</h6>
              <p class="text-white mb-0">09.00 am to 07.00 pm</p>
            </div>
            <div class="mb-3">
              <h6 class="text-muted mb-0">Satday:</h6>
              <p class="text-white mb-0">10.00 am to 05.00 pm</p>
            </div>
            <div class="mb-3">
              <h6 class="text-muted mb-0">Vacation:</h6>
              <p class="text-white mb-0">All Sunday is our vacation</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="text-secondary mb-4">Our Services</h4>
            <a href="#" class=""
              ><i class="fas fa-angle-right me-2"></i> Business</a
            >
            <a href="#" class=""
              ><i class="fas fa-angle-right me-2"></i> Evaluation</a
            >
            <a href="#" class=""
              ><i class="fas fa-angle-right me-2"></i> Migrate</a
            >
            <a href="#" class=""
              ><i class="fas fa-angle-right me-2"></i> Study</a
            >
            <a href="#" class=""
              ><i class="fas fa-angle-right me-2"></i> Counselling</a
            >
            <a href="#" class=""
              ><i class="fas fa-angle-right me-2"></i> Work / Career</a
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item">
            <h4 class="text-secondary mb-4">Newsletter</h4>
            <p class="text-white mb-3">
              Dolor amet sit justo amet elitr clita ipsum elitr est.Lorem ipsum
              dolor sit amet, consectetur adipiscing elit.
            </p>
            <div class="position-relative mx-auto rounded-pill">
              <input
                class="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Enter your email"
              />
              <button
                type="button"
                class="btn btn-primary rounded-pill position-absolute top-0 end-0 py-2 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Copyright Start -->
  <div class="container-fluid copyright py-4">
    <div class="container">
      <div class="row g-4 align-items-center">
        <div class="col-md-6 text-center text-md-start mb-md-0">
          <span class="text-white"
            ><a href="#" class="border-bottom text-white"
              ><i class="fas fa-copyright text-light me-2"></i>Elrukz Group</a
            >, All right reserved.</span
          >
        </div>
        <div class="col-md-6 text-center text-md-end text-white">
          <!--/*** This template is free as long as you keep the below author’s credit link/attribution link/backlink. ***/-->
          <!--/*** If you'd like to use the template without the below author’s credit link/attribution link/backlink, ***/-->
          <!--/*** you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". ***/-->
          <!-- Designed By <a class="border-bottom text-white" href="https://htmlcodex.com">HTML Codex</a> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright End -->

  <!-- Back to Top -->
  <a href="#" class="btn btn-primary btn-lg-square back-to-top"
    ><i class="fa fa-arrow-up"></i
  ></a>
</template>
